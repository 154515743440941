@font-face {
  font-family: 'AlimamaHei';
  font-weight: 700;
  src: url(./assets/fonts/Alimama_ShuHeiTi_Bold.ttf);
  font-display: swap;
}

@font-face {
  font-family: 'ZKArt';
  font-weight: 700;
  src: url(./assets/fonts/zcoolwenyiti.ttf);
  font-display: swap;
}

body {
  margin: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

mark {
  display: inline;
  padding: 0;
  background-color: #ffff00;
}

.ant-modal-footer {
  border-top: 0;
}
