/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.loading .inner {
  display: inline-block;
  width: auto;
  height: 40px;
  margin-bottom: 150px;
}
.loading .inner > span {
  display: inline-block;
  width: 8px;
  height: 100%;
  border-radius: 4px;
  background: lightgreen;
  animation: stretch 0.5s ease infinite;
}
.loading .inner > span:nth-child(1) {
  animation-delay: 0s;
}
.loading .inner > span:nth-child(2) {
  animation-delay: 0.1s;
}
.loading .inner > span:nth-child(3) {
  animation-delay: 0.2s;
}
.loading .inner > span:nth-child(4) {
  animation-delay: 0.3s;
}
.loading .inner > span:nth-child(5) {
  animation-delay: 0.4s;
}
.loading .inner > span + span {
  margin-left: 15px;
}
@keyframes stretch {
  0%,
  100% {
    height: 40px;
    background: lightgreen;
  }
  50% {
    height: 70px;
    margin-top: -15px;
    margin-bottom: -15px;
    background: lightblue;
  }
}
