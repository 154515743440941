/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.Home {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #333;
  color: #fff;
  overflow: auto;
}
.Home::-webkit-scrollbar {
  display: none;
}
.Home .banner {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
  background: url(../../assets/images/banner.jpeg) no-repeat center;
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.Home .banner::after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(1px);
  content: '';
  z-index: 1;
}
.Home .banner .slogan {
  position: relative;
  width: 100%;
  text-align: center;
  font-family: 'AlimamaHei';
  z-index: 2;
}
.Home .banner .slogan .title {
  display: inline-block;
  width: 100%;
  font-size: 2.5rem;
  font-weight: 600;
  letter-spacing: 10px;
  line-height: 1;
}
.Home .banner .slogan .subtitle {
  display: inline-block;
  width: 100%;
  font-size: 1.7rem;
  line-height: 1;
  letter-spacing: 3px;
  color: rgba(255, 255, 255, 0.85);
  margin-top: 30px;
}
.Home .banner .slogan .toolbar {
  margin-top: 50px;
}
.Home .banner .slogan .toolbar .button {
  position: relative;
  padding: 3px;
  display: inline-block;
  border: 0;
  outline: none;
  border-radius: 7px;
  background: rgba(255, 255, 255, 0.5);
}
.Home .banner .slogan .toolbar .button.color {
  background-image: linear-gradient(90deg, #00c0ff 0%, #ffcf00 49%, #fc4f4f 100%);
}
.Home .banner .slogan .toolbar .button .inner {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(25px);
  color: #fff;
  text-transform: uppercase;
  padding: 7px 15px;
  border-radius: 5px;
  font-weight: 300;
  font-size: 17px;
  transition: all 0.3s ease;
}
.Home .banner .slogan .toolbar .button .inner:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.7);
}
.Home .banner .slogan .toolbar .button + .button {
  margin-left: 25px;
}
.Home .banner .cardList {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  z-index: 2;
}
.Home .banner .cardList .cardWrapper {
  padding: 20px;
}
.Home .banner .cardList .cardWrapper .card {
  position: relative;
  width: 240px;
  height: 320px;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 0 25px 5px rgba(255, 255, 255, 0.5);
}
.Home .banner .cardList .cardWrapper .card.bg1 {
  background: url(../../assets/images/delivery.svg) no-repeat center;
  background-size: cover;
}
.Home .banner .cardList .cardWrapper .card.bg2 {
  background: url(../../assets/images/lawyer.svg) no-repeat center;
  background-size: cover;
}
.Home .banner .cardList .cardWrapper .card.bg3 {
  background: url(../../assets/images/nurse.svg) no-repeat center;
  background-size: cover;
}
.Home .banner .cardList .cardWrapper .card.bg4 {
  background: url(../../assets/images/reporter.svg) no-repeat center;
  background-size: cover;
}
.Home .banner .cardList .cardWrapper .card.bg5 {
  background: url(../../assets/images/worker.svg) no-repeat center;
  background-size: cover;
}
.Home .banner .cardList .cardWrapper .card::after {
  display: block;
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  margin: auto;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 1;
  transition: all 0.3s ease;
}
.Home .banner .cardList .cardWrapper .card:hover {
  transform: perspective(800px) rotateX(10deg) rotateY(20deg);
}
.Home .banner .cardList .cardWrapper .card:hover::after {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
}
.Home .banner .cardList .cardWrapper .card:hover .textBox {
  transform: translateY(0);
}
.Home .banner .cardList .cardWrapper .card:hover .textBox .title {
  font-size: 25px;
}
.Home .banner .cardList .cardWrapper .card:hover .textBox .subtitle {
  opacity: 1;
}
.Home .banner .cardList .cardWrapper .card .textBox {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  transform: translateY(65px);
  transition: all 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  font-family: 'ZKArt';
  z-index: 2;
}
.Home .banner .cardList .cardWrapper .card .textBox .title {
  font-size: 20px;
  font-weight: 300;
  text-align: right;
  transition: all 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.Home .banner .cardList .cardWrapper .card .textBox .subtitle {
  display: inline-block;
  width: 100%;
  height: 45px;
  overflow: hidden;
  font-family: 'ZKArt';
  line-height: 1.5;
  margin-top: 15px;
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.Home .module {
  display: flex;
  flex-wrap: wrap;
  padding: 40px 80px;
  background-color: #fff;
  box-sizing: border-box;
}
.Home .module .picture {
  flex: 1;
  color: #333;
}
.Home .module .picture.pic1 {
  background: url(../../assets/images/introduce.svg) no-repeat center;
  background-size: contain;
}
.Home .module .picture.pic2 {
  background: url(../../assets/images/introduce2.svg) no-repeat center;
  background-size: contain;
}
.Home .module .info {
  flex: 1;
  text-align: left;
  color: #333;
}
.Home .module .info * {
  word-break: break-all;
}
.Home .module .info h1,
.Home .module .info h2 {
  font-family: 'AlimamaHei';
}
.Home .module .info h1 {
  font-size: 40px;
  color: #00bc70;
}
.Home .module .info h1::before {
  content: '';
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 7px dashed #00bc70;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 15px;
  margin-bottom: 3px;
}
.Home .module .info h2 {
  font-size: 25px;
}
.Home .module .info section {
  font-family: 'ZKArt';
  font-size: 18px;
  text-indent: 34px;
  color: #666;
}
.Home .module .info section ul {
  text-indent: 0;
  margin-left: 34px;
}
.Home .module .info section p {
  text-indent: 68px;
}
.Home .modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(50px);
  z-index: 999;
}
.Home .modal .videoBox {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 960px;
  height: 540px;
  border: 5px solid rgba(255, 255, 255, 0.3);
  transform: translate(-50%, -50%);
  box-sizing: content-box;
}
.Home .modal .videoBox .close {
  position: absolute;
  top: 0;
  right: -45px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border: 2px solid #cc4b48;
  color: #cc4b48;
  font-size: 14px;
  outline: none;
  border-radius: 50%;
  background-color: transparent;
  transition: all 0.3s ease;
  z-index: 1000;
}
.Home .modal .videoBox .close:hover {
  box-shadow: 0 0 15px 5px rgba(227, 63, 49, 0.5);
  cursor: pointer;
}
.Home .moleskineWrapper {
  position: relative;
  max-width: calc(100% / 5);
  min-width: 10em;
  margin-top: 15px;
  z-index: 1;
}
.Home .moleskineWrapper .moleskineNotebook {
  height: 250px;
  width: 175px;
  position: relative;
  transition: 0.4s ease-in-out;
  border-radius: 5px 15px 15px 5px;
  transform-origin: left center 0px;
  display: inline-block;
  margin: 30px;
  perspective: 800px;
  box-shadow: 30px 10px 15px 10px rgba(0, 0, 0, 0.5);
}
.Home .moleskineWrapper .moleskineNotebook:hover {
  transform: rotateZ(-10deg);
}
.Home .moleskineWrapper .moleskineNotebook:hover .notebookCover {
  transform: rotateY(-70deg);
  z-index: 999;
  box-shadow: 20px 10px 50px rgba(0, 0, 0, 0.2);
}
.Home .moleskineWrapper .moleskineNotebook .notebookCover {
  background: #cc4b48;
  height: 250px;
  width: 175px;
  position: absolute;
  border-radius: 5px 15px 15px 5px;
  z-index: 10;
  transition: 0.5s linear;
  transform-style: preserve-3d;
  transform-origin: left center 0px;
}
.Home .moleskineWrapper .moleskineNotebook .notebookCover:before {
  content: '';
  position: absolute;
  width: 10px;
  height: calc(100% + 2px);
  top: -1px;
  z-index: 100;
  border-radius: 2px;
  right: 25px;
  transition: 2s ease;
  background: linear-gradient(to right, #9c2e2b 0%, #cc4b48 12%, #9c2e2b 25%, #cc4b48 37%, #9c2e2b 50%, #cc4b48 62%, #9c2e2b 75%, #cc4b48 87%, #9c2e2b 100%);
}
.Home .moleskineWrapper .moleskineNotebook .notebookCover .notebookSkin {
  position: relative;
  height: 50px;
  background: #e8e8e0;
  margin-top: 80px;
  text-indent: 15px;
  padding-top: 7px;
  font: 600 16px 'AlimamaHei', Arial, sans-serif;
  color: #222;
  text-align: left;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  letter-spacing: 3px;
  z-index: 10;
}
.Home .moleskineWrapper .moleskineNotebook .notebookCover .notebookSkin:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 15px;
  left: 0;
  bottom: 0;
  background: #cddc39;
}
.Home .moleskineWrapper .moleskineNotebook .notebookCover.blue {
  background: #2e95aa;
}
.Home .moleskineWrapper .moleskineNotebook .notebookCover.blue:before {
  background: linear-gradient(to right, #1e606e 0%, #2e95aa 12%, #1e606e 25%, #2e95aa 37%, #1e606e 50%, #2e95aa 62%, #1e606e 75%, #2e95aa 87%, #1e606e 100%);
}
.Home .moleskineWrapper .moleskineNotebook .notebookCover.green {
  background: #abc3b5;
}
.Home .moleskineWrapper .moleskineNotebook .notebookCover.green:before {
  background: linear-gradient(to right, #7ea38e 0%, #abc3b5 12%, #7ea38e 25%, #abc3b5 37%, #7ea38e 50%, #abc3b5 62%, #7ea38e 75%, #abc3b5 87%, #7ea38e 100%);
}
.Home .moleskineWrapper .moleskineNotebook .notebookCover.yellow {
  background: #fed754;
}
.Home .moleskineWrapper .moleskineNotebook .notebookCover.yellow:before {
  background: linear-gradient(to right, #ebb501 0%, #fed754 12%, #ebb501 25%, #fed754 37%, #ebb501 50%, #fed754 62%, #ebb501 75%, #fed754 87%, #ebb501 100%);
}
.Home .moleskineWrapper .moleskineNotebook .notebookCover.primary {
  background: #00bc70;
}
.Home .moleskineWrapper .moleskineNotebook .notebookCover.primary:before {
  background: linear-gradient(to right, #005633 0%, #00bc70 12%, #005633 25%, #00bc70 37%, #005633 50%, #00bc70 62%, #005633 75%, #00bc70 87%, #005633 100%);
}
.Home .moleskineWrapper .moleskineNotebook .notebookPage {
  height: 100%;
  width: 175px;
  position: absolute;
  background-color: #fbfae8;
  z-index: 0;
  border-radius: 5px 16px 16px 5px;
  overflow: hidden;
}
.Home .moleskineWrapper .moleskineNotebook .notebookPage.ruled {
  background: linear-gradient(to bottom, #fbfae8 9px, #e4e4e4 1px);
  background-size: 100% 10px;
}
.Home .moleskineWrapper .moleskineNotebook .notebookPage .thumbnail {
  padding: 15px;
}
.Home .moleskineWrapper .moleskineNotebook .notebookPage .thumbnail h1 {
  font-size: 17px;
  color: #333;
  line-height: 35px;
  margin: 0 0 10px 0;
}
.Home .moleskineWrapper .moleskineNotebook .notebookPage .thumbnail .paragraph {
  height: 160px;
  background: linear-gradient(to bottom, #fbfae8 9px, #e4e4e4 5px, #e4e4e4 1px);
  background-size: 100% 15px;
}
.Home .moleskineWrapper h4 {
  text-transform: none;
  display: block;
  color: #fff;
  font: 300 17px 'QuickSand', Arial, sans-serif;
  line-height: 1;
  text-align: center;
}
@media screen and (max-width: 900px) {
  .Home .module {
    display: flex;
    flex-direction: column;
  }
  .Home .footer .inner {
    display: flex;
    flex-direction: column;
  }
  .Home .footer .inner .box + .box {
    margin-top: 45px;
  }
}
