/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.HelperSearch {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.HelperSearch .search {
  display: flex;
  align-items: center;
  height: 70px;
  padding: 0 20px;
  min-width: 960px;
  box-sizing: border-box;
}
.HelperSearch .search.fixed {
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  background-color: #fff;
  box-shadow: -15px 0 15px 5px rgba(0, 0, 0, 0.07);
  z-index: 999;
}
.HelperSearch .search .logo {
  flex: none;
  width: 150px;
  font-size: 21px;
  color: #00bc70;
  font-family: 'AlimamaHei';
}
.HelperSearch .search .logo > span {
  margin-right: 5px;
}
.HelperSearch .search .inner {
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.HelperSearch .search .inner .input {
  position: relative;
  display: inline-block;
  width: 650px;
  height: 45px;
}
.HelperSearch .search .inner .input > input[type='text'] {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding-left: 15px;
  padding-right: 110px;
  border: 2px solid #45d692;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(7px);
  border-radius: 10px;
  box-sizing: border-box;
  outline: none;
  font-size: 15px;
  font-weight: 300;
}
.HelperSearch .search .inner .input > button {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 90px;
  height: 35px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 0;
  background-color: #00bc70;
  font-size: 15px;
  color: #fff;
}
.HelperSearch .search .inner .input > button > span {
  margin-left: 5px;
}
.HelperSearch .search .inner .input > button:hover {
  background-color: #20c980;
  cursor: pointer;
}
.HelperSearch .content {
  padding: 0 170px;
  min-width: 960px;
  box-sizing: border-box;
}
.HelperSearch .content .inner {
  width: 100%;
  margin: auto;
  text-align: left;
  padding: 20px 0;
}
.HelperSearch .content .inner .resultList {
  padding: 20px 0;
}
.HelperSearch .content .inner .resultList h1 {
  font-size: 17px;
  color: #333;
}
.HelperSearch .content .inner .resultList p {
  color: blue;
  font-size: 15px;
  text-decoration: underline;
  cursor: pointer;
  margin: 0;
  padding: 0;
}
.HelperSearch .content .inner .resultList p a {
  color: blue;
}
.HelperSearch .content .inner .resultList p:hover {
  color: #6666ff;
}
.HelperSearch .content .inner .resultList p mark {
  color: red;
  background-color: transparent;
}
