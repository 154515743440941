/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.collapse {
  border: 0;
}
.collapse :global(.ant-collapse-item) {
  border-bottom: 0 !important;
}
.collapse .panelHeader {
  font-weight: bold;
  color: #ff4d4f;
  word-break: break-all;
}
.collapse .panelHeader > span {
  margin-left: 5px;
}
.collapse .panelBody {
  text-indent: 30px;
  font-size: 14px;
}
.collapse .panelBody a {
  text-decoration: underline;
}
