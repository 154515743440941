/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.footer {
  width: 100%;
  height: auto;
  padding: 40px 20px;
  text-align: center;
  background-color: #fff;
}
.footer .inner {
  max-width: 1100px;
  margin: auto;
  display: flex;
}
.footer .inner .box {
  flex: 1;
  text-align: left;
  font-size: 14px;
}
.footer .inner .box * {
  color: #333;
}
.footer .inner .box .logo {
  display: flex;
  align-items: center;
  width: 200px;
  height: 100px;
  background: url(../../assets/images/logoCC.svg) no-repeat left;
  background-size: contain;
  color: #eee;
}
.footer .inner .box .logo .text {
  display: inline-block;
  margin-left: 70px;
}
.footer .inner .box .logo .text .main {
  font-size: 20px;
  font-family: 'ZKArt';
  letter-spacing: 3px;
  line-height: 1;
}
.footer .inner .box .logo .text .en {
  font-size: 12px;
  font-family: 'Times New Roman', Times, serif;
  line-height: 1;
}
.footer .inner .box .co {
  margin-top: 15px;
  font-size: 15px;
  font-family: 'SimSun';
}
.footer .inner .box ul {
  margin: 0;
  margin-top: 25px;
  padding: 0;
  list-style: none;
}
.footer .inner .box ul > li {
  line-height: 1;
}
.footer .inner .box ul > li + li {
  margin-top: 20px;
}
.footer .inner .box a:hover {
  text-decoration: underline;
}
.footer .inner .box h3 {
  font-weight: 600;
}
.footer .inner .box .wechat {
  width: 80px;
  height: 80px;
  background: url(../../assets/images/qrcode.jpg) no-repeat center;
  background-size: contain;
  margin-top: 10px;
}
